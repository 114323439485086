import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { AdsGETApiAuth, AdsPUTApi } from "../../../Constant/api";
import { slugs } from "../../../Constant/slugs";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
const columns = [
  {
    id: 1,
    name: "id",
    selector: (row) => row.id,
    sortable: true,
    reorder: true,
    width: "100px"
  },
  {
    id: 31,
    name: "Title",
    selector: (row) => row.title.rendered,
    sortable: true,
    reorder: true,
  },
  {
    id: 32,
    name: "Exam Time",
    selector: (row) => row.meta.exam_time,
    sortable: true,
    reorder: true
  },
  {
    id: 33,
    name: "Availability",
    selector: (row) => row.meta.available_unavailable,
    sortable: true,
    reorder: true,
    width: "150px"
  },
  {
    id: 34,
    name: "Type",
    selector: (row) => row.meta.exam_type,
    sortable: true,
    reorder: true
  },
  {
    id: 37,
    name: "Price",
    selector: (row) => row.meta.exam_price,
    sortable: true,
    reorder: true
  },
  {
    id: 3,
    name: "View",
    right: true,
    cell: (row) => <Link to={`${slugs.exam_details}${row.slug}`}>View</Link>,

  }
];

export default function AllExam() {
  const [Projects1, setProjects1] = React.useState([])
  const [Projects2, setProjects2] = React.useState([])
  const [CustomersList, setCustomersList] = React.useState([])
  const [Available, setAvailable] = React.useState(0)
  const [UnAvailable, setUnAvailable] = React.useState(0)
  const [Reload, setReload] = React.useState(false)
  const GetAllProjects = (params) => {
    setReload(true)
    setProjects1([])
    AdsGETApiAuth(params, slugs.db_slug_paid_exam)
      .then((res) => {
        setProjects2(res.data)
        setCustomersList(res.data)
        setAvailable(res.data.filter(a => a.meta.available_unavailable == "Available").length)
        setUnAvailable(res.data.filter(a => a.meta.available_unavailable == "Unavailable").length)
        console.log("done")
      })
      .catch(err => {
        console.log(err)
      }).finally(() => {
        setReload(false)
      })
  }

  const typeArray = {
    "ubt": "UBT",
    "chapter-exam": "Chapter",
    "japanese": "Japanese",
    "bridge-course": "Bridge Course",
    "lok-sewa": "Lok Sewa",
    "german": "German",
    "herbrew": "Herbrew",
  }
  const location = useLocation()
  const GetProject = (type) => {
    var paramsValue;
    if (type == "ubt")
      paramsValue = {
        "filter[meta_query][1][key]": "exam_type",
        "filter[meta_query][1][value][0]": `UBT`,
        "filter[meta_query][1][compare]": "=",
        "filter[meta_query][relation]": "OR",
        "filter[meta_query][2][key]": "exam_type",
        "filter[meta_query][2][value][0]": `Agriculture`,
        "filter[meta_query][2][compare]": "=",
        "filter[meta_query][relation]": "OR",
        "filter[meta_query][3][key]": "exam_type",
        "filter[meta_query][3][value][0]": `Ship Building`,
        "filter[meta_query][3][compare]": "=",
        "filter[meta_query][relation]": "OR",
        "filter[meta_query][4][key]": "exam_type",
        "filter[meta_query][4][value][0]": `Manufacturing`,
        "filter[meta_query][4][compare]": "=",
      }
    else
      paramsValue = {
        "filter[meta_query][1][key]": "exam_type",
        "filter[meta_query][1][value][0]": `${typeArray[type]}`,
        "filter[meta_query][1][compare]": "=",
      }
    GetAllProjects({
      per_page: 1000,
      _fields: "id,title,slug,date,meta.exam_time,meta.available_unavailable,meta.exam_type,meta.exam_price",
      ...paramsValue
    })
  }
  const history = useHistory()
  useEffect(() => {
    var type;
    type = location.pathname.slice(slugs.all_exam.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)
    GetProject(type)
    return history.listen((location) => {
      if (location.pathname.includes(slugs.all_exam)) {
        type = location.pathname.slice(slugs.all_exam.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)
        window.scrollTo(0, 0)
        GetProject(type)
      }
    })
  }, [])
  var type = location.pathname.slice(slugs.all_exam.length + 1) == "" ? "ubt" : location.pathname.slice(slugs.all_exam.length + 1)


  return (
    <div style={{}}>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>All Exam</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / All Exams
        </div>
      </div>
      <a href={`${slugs.new_exam}/${type}`}>
        <div className="sign-in-button-4" style={{ width: "150px", margin: "10px" }}
          onClick={() => {

          }}
        >Add New Exam</div>
      </a>
      <div className="row">
        <div style={{ display: "inline", cursor: "pointer" }}>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setProjects2(CustomersList)
            }}
          >
            All ({CustomersList.length}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Available"))
            }}
          >
            Available ({Available}) |
          </div>
          <div style={{ display: "inline-block", margin: "0px 5px" }}
            onClick={() => {
              setProjects2(CustomersList.filter(a => a.meta.available_unavailable == "Unavailable"))
            }}
          >
            Unavailable ({UnAvailable}) |
          </div>
          {Projects1.filter(a => a.meta.available_unavailable == "Unavailable").length !== 0 &&
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                for (let i = 0; i < Projects1.length; i++) {
                  setReload(true)
                  AdsPUTApi({
                    meta: {
                      "available_unavailable": "Available",
                    }
                  }, slugs.db_slug_paid_exam, Projects1[i].id)
                    .then(res => {
                      if (i == Projects1.length - 1) {
                        setProjects1([])
                        GetProject()
                      }
                    })
                }
              }
              }
            >
              Make Available |
            </div>
          }
          {Projects1.filter(a => a.meta.available_unavailable == "Available").length !== 0 &&
            <div style={{ display: "inline-block", margin: "0px 5px" }}
              onClick={() => {
                for (let i = 0; i < Projects1.length; i++) {
                  setReload(true)
                  AdsPUTApi({
                    meta: {
                      "available_unavailable": "Unavailable",
                    }
                  }, slugs.db_slug_paid_exam, Projects1[i].id)
                    .then(res => {
                      if (i == Projects1.length - 1) {
                        setProjects1([])
                        GetProject()
                      }
                    })
                }
              }
              }
            >
              Make Unavailable
            </div>
          }
        </div>
      </div>

      <div style={{ backgroundColor: "#fff", padding: "10px", width: "98%", margin: "10px", border: "3px solid #eee" }}>
        <DataTable
          paginationPerPage={100}
          paginationRowsPerPageOptions={[10, 50, 100, 1000]}
          columns={columns}
          data={Projects2}
          defaultSortFieldId={1}
          defaultSortAsc={false}
          striped
          // sortIcon={<SortIcon />}
          pagination
          selectableRows
          onSelectedRowsChange={(r) => {
            console.log("asdasd", r)
            setProjects1(r.selectedRows)
          }}
        />
      </div>
      {Reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}